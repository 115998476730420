import React, { useState, useEffect } from "react";
import axios from "axios";
import ProductCard from "../components/ProductCard";
import Loader from "../components/Loader";

axios.defaults.baseURL = process.env.REACT_APP_API_KEY;

const AdminPage = () => {
  const [products, setProducts] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [newProduct, setNewProduct] = useState({
    name: "",
    price: 0,
    category: "",
    description: "",
    image: null,
    video: null,
  });

  const [editingProduct, setEditingProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchProducts();
  }, []);

  const handleCancelEdit = () => {
    setEditingProduct(null);
    handleClearProductState();
  };

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("/products");
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    const email = prompt("Enter your email:");
    const password = prompt("Enter your password:");
    try {
      setLoading(true);
      const { data } = await axios.post("/login", { email, password });
      setToken(data.token);
      localStorage.setItem("token", data.token);
      alert("Login successful!");
    } catch (error) {
      alert("Login failed!");
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    setToken("");
    localStorage.removeItem("token");
    alert("Logged out!");
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setNewProduct((prev) => ({ ...prev, [name]: files[0] }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleClearProductState = () => {
    setNewProduct({
      name: "",
      price: 0,
      description: "",
      image: null,
      videoUrl: "", // Reset videoUrl as well
      category: "",
    });
  };

  const handleProductSubmit = async () => {
    if (!token) {
      alert("Please log in to add products.");
      return;
    }
    if (!newProduct.name) {
      alert("Please enter a name for the product.");
      return;
    }

    const formData = new FormData();
    formData.append("name", newProduct.name);
    formData.append("price", newProduct.price);
    formData.append("description", newProduct.description);
    formData.append("category", newProduct.category);

    // Append image if it exists
    if (newProduct.image) formData.append("image", newProduct.image);

    // Append videoUrl if it exists
    if (newProduct.videoUrl) formData.append("videoUrl", newProduct.videoUrl);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      setLoading(true);
      if (editingProduct) {
        await axios.put(`/product/${editingProduct._id}`, formData, config);
      } else {
        await axios.post("/product", formData, config);
      }

      handleClearProductState();
      setEditingProduct(null);
      fetchProducts();
    } catch (error) {
      alert("Error saving product");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (product) => {
    setEditingProduct(product);
    setNewProduct({
      name: product.name,
      price: product.price || 0,
      description: product.description,
      image: null,
      videoUrl: product.videoUrl || "",
      category: product.category,
    });
  };

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this product?"))
      return;

    try {
      setLoading(true);
      await axios.delete(`/product/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert("Product deleted successfully!");
      fetchProducts();
    } catch (error) {
      console.error("Error deleting product", error);
      alert("Error deleting product");
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <Loader />;
  return (
    <div className="p-6 max-w-4xl mx-auto">
      <header className="mb-6 flex justify-between items-center">
        <h1 className="text-2xl font-bold">Product Management</h1>
        {token ? (
          <button
            className="bg-red-500 text-white px-4 py-2 rounded"
            onClick={handleLogout}
          >
            Logout
          </button>
        ) : (
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={handleLogin}
          >
            Login
          </button>
        )}
      </header>

      {token && (
        <div className="max-w-2xl mx-auto my-8">
          <div className="bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg">
            <div className="p-6">
              <h2 className="text-2xl font-bold mb-6 text-gray-800">
                {editingProduct ? "Edit Product" : "Add Product"}
              </h2>
              <div className="space-y-4">
                <input
                  type="text"
                  name="name"
                  placeholder="Product Name"
                  value={newProduct.name}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                />
                <input
                  type="text"
                  name="category"
                  placeholder="Product Category (Optional)"
                  value={newProduct.category}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                />
                <input
                  type="number"
                  name="price"
                  placeholder="Product Price (Optional)"
                  value={newProduct.price}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                />
                <textarea
                  name="description"
                  placeholder="Product Description (Optional)"
                  value={newProduct.description}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 h-32 resize-none"
                />
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Product Image (Optional)
                  </label>
                  <div className="flex items-center w-full">
                    <input
                      type="file"
                      name="image"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <input
                  type="text"
                  name="videoUrl"
                  placeholder="Product Video URL (Optional)"
                  value={newProduct.videoUrl} // Make sure you're using videoUrl here
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                />
                <button
                  className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-all duration-300"
                  onClick={handleProductSubmit}
                >
                  {editingProduct ? "Update Product" : "Add Product"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {products?.map((product) => (
          <div key={product._id} className="w-fit  border border-gray-200 p-2">
            <ProductCard product={product} />

            {token && (
              <div className="mt-4 flex gap-4">
                <button
                  className="bg-yellow-500 text-white px-4 py-2 rounded"
                  onClick={() =>
                    editingProduct ? handleCancelEdit() : handleEdit(product)
                  }
                >
                  {editingProduct ? "Cancel" : "Edit"}
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded"
                  onClick={() => handleDelete(product._id)}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminPage;
