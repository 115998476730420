import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Product from './components/Product';
import images from './constants/images';
import HomePage from './Pages/HomePage';
import AdminPage from './Pages/AdminPage';

const App = () => {
  return (
    <>
      <Navbar />
      <Routes className="bg-zinc-100">
        <Route path="/" element={<HomePage />} />
        <Route path="/product/:productId" element={<Product />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
      <FloatingWhatsApp
        style={{ height: '0' }}
        phoneNumber="7983230034"
        accountName="Rainyware"
        avatar={images.logo}
        statusMessage={'Name You Can Always Remember'}
        styles={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
        }}
      />
      <Footer />
    </>
  );
};

export default App;
