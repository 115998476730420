import React from "react";
import {
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaMapLocation } from "react-icons/fa6";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const contactNumbers = ["9927166477", "7017909560", "6370994290"];
  const whatsAppNumbers = ["6370994290", "7983230034"];
  const email = "hkrainyware1975@gmail.com";
  const creatorName = "Deepank";
  const linkedInProfile =
    "https://www.linkedin.com/in/deepank-pushpad-747664188/";
  const address = "123 Rainy Street, Watertown, WA 98765";

  const renderContactInfo = (type, items, icon) => (
    <div className="mb-8 md:mb-0">
      <h4 className="text-lg font-semibold mb-4 flex items-center">
        {React.createElement(icon, { className: "mr-2 text-yellow-400" })}
        {type}
      </h4>
      <ul className="space-y-2">
        {items.map((item, index) => (
          <li key={index}>
            <a
              href={
                type === "Phone" || type === "WhatsApp"
                  ? `tel:${item}`
                  : type === "E-Mail"
                  ? `mailto:${item}`
                  : item
              }
              className="hover:text-yellow-400 transition-colors duration-300 flex items-center"
              target={type === "LinkedIn" ? "_blank" : undefined}
              rel={type === "LinkedIn" ? "noopener noreferrer" : undefined}
            >
              {item}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <footer className="bg-gradient-to-b from-gray-900 to-gray-800 text-gray-300 py-12 mt-3">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {renderContactInfo("Phone", contactNumbers, AiOutlinePhone)}
          {renderContactInfo("WhatsApp", whatsAppNumbers, AiOutlineWhatsApp)}
          {renderContactInfo("E-Mail", [email], AiOutlineMail)}
          <div className="mb-8 md:mb-0">
            <h4 className="text-lg font-semibold mb-4 flex items-center">
              <FaMapMarkerAlt className="mr-2 text-yellow-400" />
              Address
            </h4>
            <p className="hover:text-yellow-400 transition-colors duration-300">
              {address}
            </p>
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-700">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm mb-4 md:mb-0">
              &copy; {currentYear} Rainyware. All rights reserved.
            </p>
            <div className="flex space-x-4">
              <a
                href="https://www.google.com/maps/dir//Dwarika+Sai+Vihar,+Agra+Rd,+Aligarh,+Uttar+Pradesh+202001/@27.8543633,77.9970983,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3974a30fae840849:0xf878d2499f145241!2m2!1d78.0794997!2d27.8543878!5m2!1e4!1e1?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-yellow-400 transition-colors duration-300"
              >
                <FaMapLocation className="text-2xl" />
              </a>
            </div>
          </div>
          <div className="mt-4 text-center">
            <p className="text-sm">
              Created by{" "}
              <a
                className="text-yellow-400 hover:text-yellow-300 transition-colors duration-300"
                href={linkedInProfile}
                target="_blank"
                rel="noopener noreferrer"
              >
                {creatorName}
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
