import React, { useState } from "react";

function AboutUs() {
  const [activeTab, setActiveTab] = useState("vision");

  const tabs = [
    { id: "vision", label: "Our Vision" },
    { id: "philosophy", label: "Our Philosophy" },
    { id: "commitment", label: "Our Commitment" },
  ];

  const content = {
    vision:
      "Rainyware is a visionary brand that blends the art of design with the precision of engineering. Founded on the principles of innovation and excellence, we strive to redefine the boundaries of what's possible. Our journey began with a single goal: to create products that inspire and empower. Through tireless dedication and relentless pursuit of perfection, we've established ourselves as pioneers in the industry.",
    philosophy:
      "At Rainyware, we believe that great design is more than just aesthetics; it's about functionality, usability, and impact. That's why every product we create is meticulously crafted to deliver an unparalleled experience. From the initial concept to the final product, we pay attention to every detail to ensure that our customers receive nothing but the best. With a focus on quality, durability, and sustainability, we're committed to making a positive difference in the world.",
    commitment:
      "Our philosophy is simple: to innovate, inspire, and empower. We're constantly pushing the boundaries of what's possible, exploring new ideas, and challenging the status quo. Through collaboration, creativity, and a relentless pursuit of excellence, we're shaping the future of design and technology. Join us on this journey as we continue to transform ideas into reality and make a lasting impact on the world.",
  };

  return (
    <div className="bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-center text-4xl font-extrabold text-gray-900 sm:text-5xl mb-8">
          About <span className="text-indigo-600">Rainyware</span>
        </h2>

        <div className="bg-white shadow-xl rounded-lg overflow-hidden">
          <div className="flex border-b border-gray-200">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`flex-1 py-4 px-1 text-center text-sm font-medium ${
                  activeTab === tab.id
                    ? "text-indigo-600 border-b-2 border-indigo-600"
                    : "text-gray-500 hover:text-gray-700"
                }`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.label}
              </button>
            ))}
          </div>

          <div className="p-6 sm:p-10">
            <p className="text-lg text-gray-700 leading-relaxed">
              {content[activeTab]}
            </p>
          </div>
        </div>

        <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-3">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="p-6">
              <svg
                className="h-8 w-8 text-primary mb-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                />
              </svg>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                Innovation
              </h3>
              <p className="text-gray-600">
                Pushing boundaries and redefining possibilities in design and
                technology.
              </p>
            </div>
          </div>
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="p-6">
              <svg
                className="h-8 w-8 text-primary mb-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                />
              </svg>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                Quality
              </h3>
              <p className="text-gray-600">
                Meticulous attention to detail in every product we create.
              </p>
            </div>
          </div>
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="p-6">
              <svg
                className="h-8 w-8 text-primary mb-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                />
              </svg>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                Impact
              </h3>
              <p className="text-gray-600">
                Committed to making a positive difference in the world through
                our products.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
