import React from "react";
import ProductCard from "./ProductCard";

const CategorySection = ({ searchTerm, groupedProducts }) => {
  // Function to normalize strings by removing spaces and converting to lowercase
  const normalizeString = (str) => str.replace(/\s/g, "").toLowerCase();

  // Tokenize the search term by splitting it into words
  const searchTokens = searchTerm.trim().toLowerCase().split(/\s+/);

  // Function to filter products based on the search term
  const filterProducts = (products) => {
    return products.filter((product) => {
      // Normalize the product fields for comparison
      const normalizedName = normalizeString(product.name);
      const normalizedDescription = normalizeString(product.description || "");
      const normalizedCategory = normalizeString(product.category || "");

      // Check if any search token matches part of the product's fields (name, description, category)
      return searchTokens.some(
        (token) =>
          normalizedName.includes(token) ||
          normalizedDescription.includes(token) ||
          normalizedCategory.includes(token)
      );
    });
  };
  console.log({ groupedProducts });

  return (
    <div className="flex justify-center py-8 px-4">
      <div className="w-full">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900">Our Products</h1>
        </div>

        <div className="space-y-8">
          {/* Iterate through the grouped products and display by category */}
          {groupedProducts.length > 0 ? (
            groupedProducts.map((categoryGroup) => (
              <div key={categoryGroup.category} className="space-y-4">
                <h2 className="text-2xl font-semibold text-gray-800">
                  {categoryGroup.category}
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 justify-items-center">
                  {/* Filter and map the products for each category */}
                  {filterProducts(categoryGroup.products).length > 0 ? (
                    filterProducts(categoryGroup.products).map((product) => (
                      <div key={product.id} className="w-full">
                        <ProductCard product={product} />
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-500 w-full text-center">
                      No products found.
                    </p>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500 w-full text-center">
              No products available.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategorySection;
