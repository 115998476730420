import {
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import PrimaryButton from "../UI/Button/Button";
import { useEffect, useState } from "react";
import axios from "axios";
import images from "../constants/images";
import Loader from "./Loader";

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await axios.get("/products/unique-categories");
        setCategories(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching categories", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);

  // Map the categories dynamically
  const productChildren =
    categories.map((category) => ({
      label: category.category || "Uncategorized", // Display category or "Uncategorized"
      href: `/product/${category.product._id}`, // Redirect to /product/:id
    })) || [];
  if (loading) return <Loader />;
  return (
    <Box className="p-2">
      <Flex
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        align={"center"}
        justify={"space-between"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
          alignItems={"center"}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <div className="items-center flex justify-between lg:w-full md:w-full">
          <Link to="/">
            <img src={images.logo} className="aspect-auto object-cover w-36" />
          </Link>

          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav productChildren={productChildren} />
          </Flex>
        </div>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav productChildren={productChildren} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = ({ productChildren }) => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <div
      direction={"row"}
      spacing={4}
      className="flex justify-center items-center"
    >
      <Box key="products">
        <Popover trigger={"hover"} placement={"bottom-start"}>
          <PopoverTrigger>
            <Box
              as="a"
              p={4}
              href="#"
              fontSize={"md"}
              fontWeight={500}
              color={linkColor}
              _hover={{
                textDecoration: "none",
                color: linkHoverColor,
              }}
            >
              Products
            </Box>
          </PopoverTrigger>

          <PopoverContent
            border={0}
            boxShadow={"xl"}
            bg={popoverContentBgColor}
            p={4}
            rounded={"xl"}
            minW={"sm"}
          >
            <Stack maxH="300px" overflowY="auto">
              {productChildren.map((child) => (
                <DesktopSubNav key={child.label} {...child} />
              ))}
            </Stack>
          </PopoverContent>
        </Popover>
      </Box>
      <a href="#contact">
        <PrimaryButton title={"Contact Us"} />
      </a>
    </div>
  );
};

const DesktopSubNav = ({ label, href }) => {
  return (
    <Box
      as="a"
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

const MobileNav = ({ productChildren }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {productChildren.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
      <a href="#contact">
        <PrimaryButton title={"Contact Us"} />
      </a>
    </Stack>
  );
};

const MobileNavItem = ({ label, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={onToggle}>
      <Box
        py={2}
        as="a"
        href={href}
        justifyContent="space-between"
        alignItems="center"
        _hover={{ textDecoration: "none" }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {href && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          <Box as="a" key={href} py={2} href={href}>
            {label}
          </Box>
        </Stack>
      </Collapse>
    </Stack>
  );
};
