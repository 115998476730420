import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import AboutUs from "../components/AboutUs";
import CategorySection from "../components/CategorySection";
import ContactForm from "../components/ContactUs";
import HeroSection from "../components/HeroSection";
import Loader from "../components/Loader";
import PdfViewer from "../components/PdfViewer";
const HomePage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const searchTermFromPath = pathSegments[pathSegments.length - 1];
    const decodedSearchTerm = decodeURIComponent(searchTermFromPath); // Decode the search term
    setSearchTerm(decodedSearchTerm);
  }, [location.pathname]);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };
  const fetchProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("/products/grouped");
      setProducts(data);
    } catch (error) {
      console.error("Error fetching products", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  if (loading) return <Loader />;
  return (
    <>
      <HeroSection onSearch={handleSearch} searchTerm={searchTerm} />
      <div className="container mx-auto">
        <CategorySection searchTerm={searchTerm} groupedProducts={products} />
      </div>
      <AboutUs />
      <PdfViewer />
      <ContactForm />
    </>
  );
};

export default HomePage;
