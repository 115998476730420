import React, { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { FaArrowLeft, FaArrowRight, FaDownload } from "react-icons/fa";
import { RAINYWARE_PDF } from "../constants/data";

// Set up the pdf.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PdfViewer = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState(1);
  const pdfContainerRef = useRef(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) =>
      Math.min(Math.max(prevPageNumber + offset, 1), numPages)
    );
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = RAINYWARE_PDF;
    link.download = "rainyware.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Set the scale dynamically based on the container width
  useEffect(() => {
    const handleResize = () => {
      if (pdfContainerRef.current) {
        const containerWidth = pdfContainerRef.current.offsetWidth;
        // Adjust scale for the container width
        setScale(containerWidth / 800); // Example: Assume 800px is the ideal width for scale 1
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="flex justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8"
      id="about"
    >
      <div className="w-full max-w-4xl bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="p-6 bg-indigo-600/80 text-white">
          <h2 className="text-2xl font-bold">Rainyware Product Catalog</h2>
          <p className="mt-1 text-indigo-200">
            Browse our latest offerings and innovations
          </p>
        </div>

        <div className="p-6">
          <div className="flex flex-wrap items-center justify-center mb-6">
            <div className="flex space-x-2">
              <button
                onClick={() => changePage(-1)}
                disabled={pageNumber <= 1}
                className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              >
                <FaArrowLeft className="inline-block mr-2" />
                Previous
              </button>
              <button
                onClick={() => changePage(1)}
                disabled={pageNumber >= numPages}
                className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
              >
                Next
                <FaArrowRight className="inline-block ml-2" />
              </button>
              <button
                onClick={handleDownload}
                className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-colors duration-200"
              >
                <FaDownload className="inline-block mr-2" />
                Download
              </button>
            </div>
          </div>

          <div
            ref={pdfContainerRef}
            className="relative border border-gray-200 rounded-lg overflow-hidden"
          >
            <Document
              file={RAINYWARE_PDF}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={
                <div className="flex justify-center items-center h-96">
                  <div className="relative w-24 h-24">
                    <div className="absolute top-0 left-0 right-0 bottom-0 border-8 border-indigo-200 rounded-full"></div>
                    <div className="absolute top-0 left-0 right-0 bottom-0 border-8 border-indigo-600 rounded-full animate-spin border-t-transparent"></div>
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-indigo-600 font-semibold">
                      Loading
                    </div>
                  </div>
                </div>
              }
            >
              <Page
                width={"800"}
                pageNumber={pageNumber}
                scale={scale}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Document>
            {loading && (
              <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75">
                <div className="relative w-20 h-20">
                  <div className="absolute top-0 left-0 right-0 bottom-0 border-4 border-indigo-200 rounded-full"></div>
                  <div className="absolute top-0 left-0 right-0 bottom-0 border-4 border-indigo-600 rounded-full animate-spin border-t-transparent"></div>
                </div>
              </div>
            )}
          </div>

          <div className="mt-4 text-center text-gray-600">
            Page {pageNumber} of {numPages}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfViewer;
