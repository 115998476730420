import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "./ProductCard";
import Loader from "./Loader";

const Product = () => {
  const { productId } = useParams();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/product/${productId}`);
        setProduct(response.data.product);
        setSimilarProducts(response.data.similarProducts); // Set similar products
      } catch (error) {
        alert(error?.response?.data?.message || "Error fetching product");
      } finally {
        setLoading(false);
      }
    };
    fetchProduct();
  }, [productId]); // Add productId as a dependency to refetch on ID change
  if (loading) return <Loader />;
  return (
    <div className="flex flex-col items-center justify-center w-full py-8 px-4">
      {/* Main Product */}
      {product && (
        <div className="w-full max-w-[400px] bg-white  rounded-lg overflow-hidden mb-8 p-6">
          <ProductCard product={product} />
        </div>
      )}

      {/* Similar Products */}
      {similarProducts?.length > 0 && (
        <div className="w-full mt-12">
          <h2 className="text-3xl font-semibold text-gray-900 text-center mb-6">
            Similar Products
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 justify-items-center">
            {similarProducts.map((similarProduct) => (
              <div
                key={similarProduct._id}
                className="w-full max-w-[300px] bg-white shadow-md rounded-lg overflow-hidden transition-transform transform hover:scale-105 duration-200"
              >
                <ProductCard product={similarProduct} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
