import React, { useState, useEffect } from "react";
import { FaSearch, FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion";
import images from "../constants/images";

const HeroSection = ({ onSearch, searchTerm: initialSearchTerm }) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  useEffect(() => {
    setSearchTerm(initialSearchTerm);
  }, [initialSearchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <div className="relative h-screen flex items-center justify-center overflow-hidden">
      <div className="absolute inset-0 z-0">
        <img
          src={images.herosectionBg}
          className="w-full h-full object-cover"
          alt="Hero Background"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>

      <div className="relative z-10 text-center px-4 sm:px-6 lg:px-8">
        <motion.h1
          className="text-4xl sm:text-5xl lg:text-6xl font-extrabold text-white mb-6"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          A Name You'll Always Remember
        </motion.h1>

        <motion.p
          className="text-xl sm:text-2xl text-gray-300 mb-8"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          Discover the perfect product for your needs
        </motion.p>

        <motion.div
          className="max-w-3xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <div
            className={`flex items-center bg-white rounded-full overflow-hidden transition-all duration-300 ${
              isSearchFocused ? "ring-2 ring-yellow-400" : ""
            }`}
          >
            <FaSearch className="text-gray-400 ml-4" size={20} />
            <input
              type="text"
              placeholder="Search for products..."
              value={searchTerm}
              onChange={handleSearchChange}
              onFocus={() => setIsSearchFocused(true)}
              onBlur={() => setIsSearchFocused(false)}
              className="w-full px-4 py-3 text-gray-800 focus:outline-none"
            />
            <button
              onClick={() => onSearch(searchTerm)}
              className="bg-yellow-400 hover:bg-yellow-500 text-white px-6 py-3 transition-colors duration-300 flex items-center"
            >
              <span className="hidden sm:inline mr-2">Search</span>
              <FaArrowRight />
            </button>
          </div>
        </motion.div>

        <motion.div
          className="mt-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <p className="text-white/80">
            Popular searches:
            <button
              className="ml-2 text-yellow-400 hover:underline focus:outline-none"
              onClick={() => onSearch("Bathroom")}
            >
              Bathroom
            </button>
            ,
            <button
              className="ml-2 text-yellow-400 hover:underline focus:outline-none"
              onClick={() => onSearch("Kitchen")}
            >
              Kitchen
            </button>
            ,
            <button
              className="ml-2 text-yellow-400 hover:underline focus:outline-none"
              onClick={() => onSearch("Accessories")}
            >
              Accessories
            </button>
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default HeroSection;
