import herosectionBg from "../assets/images/hero2.jpg";
import logo from "../assets/images/logo.png";
// Recursive function to import all files from nested folders
const importAllNested = (r) => {
  let images = {};
  r.keys().forEach((key) => {
    if (key.startsWith("./") && key !== "./index.js") {
      const path = key.substring(2); // Remove './' prefix
      const parts = path.split("/");
      let current = images;
      parts.forEach((part, index) => {
        if (index === parts.length - 1) {
          current[part.replace(/\.(png|jpe?g|svg)$/, "")] = r(key);
        } else {
          current[part] = current[part] || {};
          current = current[part];
        }
      });
    }
  });
  return images;
};

export default {
  logo,
  herosectionBg,
};
