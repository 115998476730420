import React from "react";
import { useNavigate } from "react-router";

const ProductCard = ({ product }) => {
  const { name, price, description, imageUrl, videoUrl } = product;
  const navigate = useNavigate();

  return (
    <div className="rounded-lg shadow-lg bg-white  overflow-hidden transition-all duration-300 hover:shadow-xl">
      {/* Image Section */}
      <div className="relative aspect-square">
        {imageUrl ? (
          <img
            onClick={() => navigate(`/product/${product._id}`)}
            src={imageUrl}
            alt={name}
            className="w-full h-full object-cover transition-transform duration-300 hover:scale-105 cursor-pointer"
          />
        ) : (
          <div className="w-full h-full bg-gray-200  flex items-center justify-center text-gray-500 ">
            No Image
          </div>
        )}
      </div>

      <div className="p-4">
        {/* Product Name */}
        <h2 className="text-xl font-semibold text-gray-900 ">{name}</h2>

        {/* Price Section */}
        {price > 0 && (
          <p className="text-lg font-bold text-green-600 ">
            ₹{price?.toFixed(2)}
          </p>
        )}

        {/* Description Section */}
        {description && <p className="text-sm text-gray-600 ">{description}</p>}

        {/* Actions Section */}
        <div className="mt-4 flex justify-between items-center">
          {videoUrl && (
            <a
              href={videoUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800  transition-colors duration-300 flex items-center space-x-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Watch Video</span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
